@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("https://illdoitsecond.vercel.app/grid2.png");
  background-position: top;
  background-size: contain;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animtxt {
  color: hsl(0, 0%, 100%);
  /* font-size: 50px!important; */
  /* font-weight: bold!important; */
  /* font-family: monospace; */
  letter-spacing: 7px!important;
  cursor: pointer;
  /* text-transform: uppercase; */
}

.animtxt {
  background: linear-gradient(to right, hsl(0, 0%, 100%) 0, hsl(0, 100%, 56%) 10%, hsl(0, 0%, 100%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 10s infinite linear;
  backface-visibility: hidden;
}

.yaebysobak {
  background-image: url("http://localhost:3000/bg.png");
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 600px;
  }
  100% {
    background-position: 600px;
  }
}

.extension-item {
  transition: transform 5s ease-in-out;
}

.content {
	position: relative;
}

.content p {
	color: #fff;
	font-size: 2em;
	position: absolute;

}
.content p:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 0.9px #4585FF;
  transform: translate(-0.5px, -0.5px);
}
.content p:nth-child(2) {
	color: transparent;
	-webkit-text-stroke: 1px #FF4545;
  transform: translate(0.5px, 0.5px);
}


.content p:nth-child(3) {
	color: #FFFFFF;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}
